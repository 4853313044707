import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'

import createStore from './createStore'

const store = createStore()

const persistor = persistStore(store)

persistStore(
	store,
	null,
	() => store.getState()
)

export default ({ element }) => {
  const ConnectedRootElement = (
    <Provider store={store} persistor={persistor}>
        {element}
    </Provider>
  )
  return ConnectedRootElement
}
