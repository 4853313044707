import { createStore, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'

import rootReducer from '../reducers'
import { STAGING } from '../theme/actionTypes'

let store = null

export default () => {
	if (STAGING()) {
		store = createStore(
			rootReducer,
			undefined,
			applyMiddleware(thunk, logger),
		)
	} else {
		store = createStore(
			rootReducer,
			undefined,
			applyMiddleware(thunk),
		)
	}

	return store
}
