/* Action types */
export const STAGING = () => process.env.STAGING && process.env.STAGING.includes('true') ? 1 : 0
export const API = STAGING() ? 'https://flourishstage-api.azurewebsites.net/api' : 'https://flourish-api.azurewebsites.net/api'
export const TEST_HEADER = STAGING()
export const PAYMENT_STRIPEKEY = STAGING() ? 'pk_test_MR1V8Kocl7Ks46LoVuAZljow' : 'pk_live_lyrW9QnN7Mtnr8QGxVlqPt6z'
export const DONATE_STRIPEKEY = STAGING() ? 'pk_test_Z8hrdj8NDlpOLl6GDswgXQz4' : 'pk_live_Ho88AuE7HZ3S2YCQ5stIkLFZ'
export const DASHBOARD = STAGING() ? "https://dashboard-staging-85e5d.firebaseapp.com" : "https://dashboard.flourishchange.com"
// https://staging-233817.appspot.com, http://127.0.0.1:5000
export const ADMINAPI = STAGING() ? 'https://staging-233817.appspot.com' : 'https://com-flourish-flourish.appspot.com'

// Auth types
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'
export const LOGOUT = 'LOGOUT'
export const CHECK_ADMIN_REQUEST = 'CHECK_ADMIN_REQUEST'
export const CHECK_ADMIN_SUCCESS = 'CHECK_ADMIN_SUCCESS'
export const CHECK_ADMIN_FAILURE = 'CHECK_ADMIN_FAILURE'
export const FORGOT_REQUEST = 'FORGOT_REQUEST'
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS'
export const FORGOT_FAILURE = 'FORGOT_FAILURE'
export const RESET_REQUEST = 'RESET_REQUEST'
export const RESET_SUCCESS = 'RESET_SUCCESS'
export const RESET_FAILURE = 'RESET_FAILURE'

// Donate now types
export const GET_ORGS_REQUEST = 'GET_ORGS_REQUEST'
export const GET_ORGS_SUCCESS = 'GET_ORGS_SUCCESS'
export const GET_ORGS_FAILURE = 'GET_ORGS_FAILURE'
export const GET_ORG_CATEGORIES = 'GET_ORG_CATEGORIES'
export const GET_ORG_CATEGORIES_FAIL = 'GET_ORG_CATEGORIES_FAIL'
export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST'
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS'
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE'
export const SELECT_ORG = 'SELECT_ORG'
export const SELECT_PROJ = 'SELECT_PROJ'
export const SELECT_PROJECTS = 'SELECT_PROJECTS'
export const SET_ORG_SUCCESS = 'SET_ORG_SUCCESS'
export const SET_ORG_REQUEST = 'SET_ORG_REQUEST'
export const SET_ORG_FAILURE = 'SET_ORG_FAILURE'
export const SET_PROJ_SUCCESS = 'SET_PROJ_SUCCESS'
export const SET_PROJ_REQUEST = 'SET_PROJ_REQUEST'
export const SET_PROJ_FAILURE = 'SET_PROJ_FAILURE'
export const SET_DAILY_DONATION_REQUEST = 'SET_DAILY_DONATION_REQUEST'
export const SET_DAILY_DONATION_SUCCESS = 'SET_DAILY_DONATION_SUCCESS'
export const SET_DAILY_DONATION_FAILURE = 'SET_DAILY_DONATION_FAILURE'
export const CLEAR_ORG_AND_PROJ = 'CLEAR_ORG_AND_PROJ'
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS'
export const CLEAR_SELECTED_PROJ = 'CLEAR_SELECTED_PROJ'
export const DONATE_NOW_SUCCESS = 'DONATE_NOW_SUCCESS'
export const DONATE_NOW_REQUEST = 'DONATE_NOW_REQUEST'
export const DONATE_NOW_FAILURE = 'DONATE_NOW_FAILURE'
export const ONE_TIME_DONATION_AUTHED_REQUEST = 'ONE_TIME_DONATION_AUTHED_REQUEST'
export const ONE_TIME_DONATION_AUTHED_SUCCESS = 'ONE_TIME_DONATION_AUTHED_SUCCESS'
export const ONE_TIME_DONATION_AUTHED_FAILURE = 'ONE_TIME_DONATION_AUTHED_FAILURE'
export const CLEAR_DONATE_NOW = 'CLEAR_DONATE_NOW'
export const LINK_BANK_SUCCESS = 'LINK_BANK_SUCCESS'
export const LINK_BANK_FAILURE = 'LINK_BANK_FAILURE'
export const LINK_BANK_REQUEST = 'LINK_BANK_REQUEST'
export const LINK_CREDIT_CARD_SUCCESS = 'LINK_CREDIT_CARD_SUCCESS'
export const LINK_CREDIT_CARD_FAILURE = 'LINK_CREDIT_CARD_FAILURE'
export const LINK_CREDIT_CARD_REQUEST = 'LINK_CREDIT_CARD_REQUEST'


// Notification types
export const ERROR = 'ERROR'
export const MESSAGE = 'MESSAGE'
export const CLEAR = 'CLEAR'

// Admin actions
export const GET_ORG_SUCCESS = 'GET_ORG_SUCCESS'
export const GET_ORG_FAILURE = 'GET_ORG_FAILURE'
export const CLEAR_ORG = 'CLEAR_ORG'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const CLEAR_AUTH = 'CLEAR_AUTH'
export const CHANGE_SELECTED_PROJECT = 'CHANGE_SELECTED_PROJECT'

// Analytics
export const GET_DONATIONS_SUCCESS = 'GET_DONATIONS_SUCCESS'
export const GET_DONATIONS_FAILURE = 'GET_DONATIONS_FAILURE'
export const GET_DONATIONS_REQUEST = 'GET_DONATIONS_REQUEST'

// messageActions
export const GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS'
export const GET_CONVERSATIONS_FAIL = 'GET_CONVERSATIONS_FAIL'
export const GET_CONVERSATIONS_REQUEST = 'GET_CONVERSATIONS_REQUEST'
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS'
export const CREATE_CONVERSATION_FAIL = 'CREATE_CONVORSATION_FAIL'
export const CREATE_CONVERSATION_REQUEST = "CREATE_CONVERSATION_REQUEST"
export const SELECT_MESSAGE_ORG = 'SELECT_MESSAGE_ORG'
export const CLEAR_CONVERSATION = 'CLEAR_CONVERSATION'

// Onboarding Actions
export const ADD_ORG_REQUEST = 'ADD_ORG_REQUEST'
export const ADD_ORG_SUCCESS = 'ADD_ORG_SUCCESS'
export const ADD_ORG_FAIL = 'ADD_ORG_FAIL'
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL'
export const COUPON_REQUEST = 'COUPON_REQUEST'
export const COUPON_SUCCESS = 'GET_COUPON_SUCCESS'
export const COUPON_FAIL = "GET_COUPON_FAIL"
export const CLEAR_COUPON = "CLEAR_COUPON"
export const SEND_SLACK_REQUEST = 'SEND_SLACK_REQUEST'
export const SEND_CHECK_SUCCESS = "SEND_CHECK_SUCCESS"
export const SEND_SLACK_ERROR = "SEND_SLACK_ERROR"
