// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-posts-account-and-donations-js": () => import("../src/pages/blog/posts/account_and_donations.js" /* webpackChunkName: "component---src-pages-blog-posts-account-and-donations-js" */),
  "component---src-pages-blog-posts-millennials-become-donors-js": () => import("../src/pages/blog/posts/millennialsBecomeDonors.js" /* webpackChunkName: "component---src-pages-blog-posts-millennials-become-donors-js" */),
  "component---src-pages-blog-posts-millennials-chapter-1-js": () => import("../src/pages/blog/posts/MillennialsChapter1.js" /* webpackChunkName: "component---src-pages-blog-posts-millennials-chapter-1-js" */),
  "component---src-pages-blog-posts-millennials-chapter-2-js": () => import("../src/pages/blog/posts/MillennialsChapter2.js" /* webpackChunkName: "component---src-pages-blog-posts-millennials-chapter-2-js" */),
  "component---src-pages-blog-posts-millennials-chapter-3-js": () => import("../src/pages/blog/posts/MillennialsChapter3.js" /* webpackChunkName: "component---src-pages-blog-posts-millennials-chapter-3-js" */),
  "component---src-pages-blog-posts-millennials-chapter-4-js": () => import("../src/pages/blog/posts/MillennialsChapter4.js" /* webpackChunkName: "component---src-pages-blog-posts-millennials-chapter-4-js" */),
  "component---src-pages-blog-posts-millennials-chapter-5-js": () => import("../src/pages/blog/posts/MillennialsChapter5.js" /* webpackChunkName: "component---src-pages-blog-posts-millennials-chapter-5-js" */),
  "component---src-pages-blog-posts-millennials-chapter-6-js": () => import("../src/pages/blog/posts/MillennialsChapter6.js" /* webpackChunkName: "component---src-pages-blog-posts-millennials-chapter-6-js" */),
  "component---src-pages-blog-posts-millennials-chapter-7-js": () => import("../src/pages/blog/posts/MillennialsChapter7.js" /* webpackChunkName: "component---src-pages-blog-posts-millennials-chapter-7-js" */),
  "component---src-pages-checkout-js": () => import("../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-donate-js": () => import("../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-financing-js": () => import("../src/pages/financing.js" /* webpackChunkName: "component---src-pages-financing-js" */),
  "component---src-pages-forgot-js": () => import("../src/pages/forgot.js" /* webpackChunkName: "component---src-pages-forgot-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-m-404-js": () => import("../src/pages/m/404.js" /* webpackChunkName: "component---src-pages-m-404-js" */),
  "component---src-pages-m-checkout-js": () => import("../src/pages/m/checkout.js" /* webpackChunkName: "component---src-pages-m-checkout-js" */),
  "component---src-pages-m-donate-js": () => import("../src/pages/m/donate.js" /* webpackChunkName: "component---src-pages-m-donate-js" */),
  "component---src-pages-m-index-js": () => import("../src/pages/m/index.js" /* webpackChunkName: "component---src-pages-m-index-js" */),
  "component---src-pages-m-marketing-js": () => import("../src/pages/m/marketing.js" /* webpackChunkName: "component---src-pages-m-marketing-js" */),
  "component---src-pages-m-nonprofits-index-js": () => import("../src/pages/m/nonprofits/index.js" /* webpackChunkName: "component---src-pages-m-nonprofits-index-js" */),
  "component---src-pages-m-nonprofits-results-js": () => import("../src/pages/m/nonprofits/results.js" /* webpackChunkName: "component---src-pages-m-nonprofits-results-js" */),
  "component---src-pages-m-nonprofits-thankyou-js": () => import("../src/pages/m/nonprofits/thankyou.js" /* webpackChunkName: "component---src-pages-m-nonprofits-thankyou-js" */),
  "component---src-pages-m-one-time-donation-js": () => import("../src/pages/m/one-time-donation.js" /* webpackChunkName: "component---src-pages-m-one-time-donation-js" */),
  "component---src-pages-m-organization-thankyou-js": () => import("../src/pages/m/organizationThankyou.js" /* webpackChunkName: "component---src-pages-m-organization-thankyou-js" */),
  "component---src-pages-m-pricing-js": () => import("../src/pages/m/pricing.js" /* webpackChunkName: "component---src-pages-m-pricing-js" */),
  "component---src-pages-m-privacy-js": () => import("../src/pages/m/privacy.js" /* webpackChunkName: "component---src-pages-m-privacy-js" */),
  "component---src-pages-m-security-js": () => import("../src/pages/m/security.js" /* webpackChunkName: "component---src-pages-m-security-js" */),
  "component---src-pages-m-terms-and-conditions-js": () => import("../src/pages/m/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-m-terms-and-conditions-js" */),
  "component---src-pages-m-thankyou-js": () => import("../src/pages/m/thankyou.js" /* webpackChunkName: "component---src-pages-m-thankyou-js" */),
  "component---src-pages-marketing-js": () => import("../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-nonprofits-index-js": () => import("../src/pages/nonprofits/index.js" /* webpackChunkName: "component---src-pages-nonprofits-index-js" */),
  "component---src-pages-nonprofits-results-js": () => import("../src/pages/nonprofits/results.js" /* webpackChunkName: "component---src-pages-nonprofits-results-js" */),
  "component---src-pages-nonprofits-thankyou-js": () => import("../src/pages/nonprofits/thankyou.js" /* webpackChunkName: "component---src-pages-nonprofits-thankyou-js" */),
  "component---src-pages-one-time-donation-js": () => import("../src/pages/one-time-donation.js" /* webpackChunkName: "component---src-pages-one-time-donation-js" */),
  "component---src-pages-organization-thankyou-js": () => import("../src/pages/organizationThankyou.js" /* webpackChunkName: "component---src-pages-organization-thankyou-js" */),
  "component---src-pages-pricing-js": () => import("../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-redirects-my-donations-choices-js": () => import("../src/pages/redirects/my-donations-choices.js" /* webpackChunkName: "component---src-pages-redirects-my-donations-choices-js" */),
  "component---src-pages-redirects-unsubscribe-js": () => import("../src/pages/redirects/unsubscribe.js" /* webpackChunkName: "component---src-pages-redirects-unsubscribe-js" */),
  "component---src-pages-register-js": () => import("../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-js": () => import("../src/pages/reset.js" /* webpackChunkName: "component---src-pages-reset-js" */),
  "component---src-pages-security-js": () => import("../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-sms-redirect-js": () => import("../src/pages/smsRedirect.js" /* webpackChunkName: "component---src-pages-sms-redirect-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

