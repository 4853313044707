// Frameworks necessary for state persistance
import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// Import reducers from other files
import donateNow from './donateNow'
import utilReducer from './utilReducer'

// Config necessary for state persistance
const config = {
	key: 'primary',
	storage,
	timeout: null,
}

// Root reducer combines all separate reducers and calls appropriate one
const rootReducer = persistCombineReducers(config, {
	donateNow: donateNow,
	utilState: utilReducer
})

export default rootReducer
