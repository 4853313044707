import {
	ADD_ORG_REQUEST,
	ADD_ORG_SUCCESS,
	ADD_ORG_FAIL,
	GET_CATEGORIES_REQUEST,
	GET_CATEGORIES_SUCCESS,
	GET_CATEGORIES_FAIL,
	COUPON_SUCCESS,
	COUPON_FAIL,
	SEND_SLACK_REQUEST,
	SEND_CHECK_SUCCESS,
	SEND_SLACK_ERROR,
	COUPON_REQUEST,
	CLEAR_COUPON
} from '../theme/actionTypes'

const initialState = {
	pending: true,
	success: false,
	error: "",
	couponError: '',
	categories: [],
	coupon: {},
	couponValid: false,
	couponPending: false,
	sendCheckSuccess: false
}

const utilReducer = (state = initialState, action) => {
	switch (action.type) {
	case SEND_SLACK_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		newState.error = ''
		return newState
	}
	case COUPON_REQUEST: {
		const newState = Object.assign({}, state)
		newState.couponPending = true
		newState.couponError = ''
		return newState
	}
	case SEND_CHECK_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.sendCheckSuccess = true
		newState.error = ''
		return newState
	}
	case ADD_ORG_REQUEST:
	{
		const newState = Object.assign({}, state)
		newState.pending = true
		newState.success = false
		newState.error = ''
		return newState
	}
	case ADD_ORG_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = ''
		return newState
	}
	case ADD_ORG_FAIL: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = action.payload
		return newState
	}
	case GET_CATEGORIES_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		newState.success = false
		newState.error = ''
		return newState
	}
	case GET_CATEGORIES_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.categories = action.payload
		newState.error = ''
		return newState
	}
	case GET_CATEGORIES_FAIL: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = action.payload
		return newState
	} case COUPON_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.coupon = action.payload
		newState.couponPending = false
		newState.couponValid = action.payload.valid
		newState.couponError = ''
		return newState
	} case COUPON_FAIL: {
		const newState = Object.assign({}, state)
		newState.couponError = action.payload
		newState.couponValid = false
		newState.couponPending = false
		return newState
	} case CLEAR_COUPON: {
		const newState = Object.assign({}, state)
		newState.couponPending = false
		newState.couponError = ''
		newState.couponValid = false
		newState.coupon = {}
		return newState
	} case SEND_SLACK_ERROR: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = 'Could not send check alert to Flourish. Please email nonprofits@flourishchange.com to alert us to your incoming check.'
		return newState
	}
	default: {return state}
	}
}

export default utilReducer
